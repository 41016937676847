import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import Turbolinks from 'turbolinks'

import 'alpinejs'
import 'tailwindcss/tailwind.css'

require.context('../images', true)
import 'controllers'
import '../css/application.scss'

Rails.start()
ActiveStorage.start()
Turbolinks.start()
