import { Controller } from 'stimulus'

import Swiper, { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Pagination])

export default class extends Controller {
  static targets = ['pagination']

  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1.25,
      spaceBetween: 16,
      centeredSlides: true,
      autoplay: {
        delay: 8000,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true
      },
      breakpoints: {
        768: {
          slidesPerView: 1.75,
        },
      }
    })
  }
}